"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
var util_1 = require("./util");
var store_1 = require("./store");
var notifier = {
    name: 'honeybadger-js',
    url: 'https://github.com/honeybadger-io/honeybadger-js',
    version: '__VERSION__'
};
// Split at commas and spaces
var TAG_SEPARATOR = /,|\s+/;
// Checks for non-blank characters
var NOT_BLANK = /\S/;
var Client = /** @class */ (function () {
    function Client(opts, transport) {
        if (opts === void 0) { opts = {}; }
        this.__pluginsExecuted = false;
        this.__store = null;
        this.__beforeNotifyHandlers = [];
        this.__afterNotifyHandlers = [];
        this.config = __assign({ apiKey: null, endpoint: 'https://api.honeybadger.io', environment: null, hostname: null, projectRoot: null, component: null, action: null, revision: null, reportData: null, breadcrumbsEnabled: true, maxBreadcrumbs: 40, maxObjectDepth: 8, logger: console, developmentEnvironments: ['dev', 'development', 'test'], debug: false, tags: null, enableUncaught: true, enableUnhandledRejection: true, afterUncaught: function () { return true; }, filters: ['creditcard', 'password'], __plugins: [] }, opts);
        this.__initStore();
        this.__transport = transport;
        this.logger = (0, util_1.logger)(this);
    }
    Client.prototype.getVersion = function () {
        return notifier.version;
    };
    Client.prototype.configure = function (opts) {
        var _this = this;
        if (opts === void 0) { opts = {}; }
        for (var k in opts) {
            this.config[k] = opts[k];
        }
        if (!this.__pluginsExecuted) {
            this.__pluginsExecuted = true;
            this.config.__plugins.forEach(function (plugin) { return plugin.load(_this); });
        }
        return this;
    };
    Client.prototype.__initStore = function () {
        this.__store = new store_1.GlobalStore({ context: {}, breadcrumbs: [] }, this.config.maxBreadcrumbs);
    };
    Client.prototype.beforeNotify = function (handler) {
        this.__beforeNotifyHandlers.push(handler);
        return this;
    };
    Client.prototype.afterNotify = function (handler) {
        this.__afterNotifyHandlers.push(handler);
        return this;
    };
    Client.prototype.setContext = function (context) {
        if (typeof context === 'object' && context != null) {
            this.__store.setContext(context);
        }
        return this;
    };
    Client.prototype.resetContext = function (context) {
        this.logger.warn('Deprecation warning: `Honeybadger.resetContext()` has been deprecated; please use `Honeybadger.clear()` instead.');
        this.__store.clear();
        if (typeof context === 'object' && context !== null) {
            this.__store.setContext(context);
        }
        return this;
    };
    Client.prototype.clear = function () {
        this.__store.clear();
        return this;
    };
    Client.prototype.notify = function (noticeable, name, extra) {
        var _this = this;
        if (name === void 0) { name = undefined; }
        if (extra === void 0) { extra = undefined; }
        var preConditionError = null;
        var notice = this.makeNotice(noticeable, name, extra);
        if (!notice) {
            this.logger.debug('failed to build error report');
            preConditionError = new Error('failed to build error report');
        }
        if (!preConditionError && this.config.reportData === false) {
            this.logger.debug('skipping error report: honeybadger.js is disabled', notice);
            preConditionError = new Error('honeybadger.js is disabled');
        }
        if (!preConditionError && this.__developmentMode()) {
            this.logger.log('honeybadger.js is in development mode; the following error report will be sent in production.', notice);
            preConditionError = new Error('honeybadger.js is in development mode');
        }
        if (!preConditionError && !this.config.apiKey) {
            this.logger.warn('could not send error report: no API key has been configured', notice);
            preConditionError = new Error('missing API key');
        }
        // we need to have the source file data before the beforeNotifyHandlers,
        // in case they modify them
        var sourceCodeData = notice && notice.backtrace ? notice.backtrace.map(function (trace) { return (0, util_1.shallowClone)(trace); }) : null;
        var beforeNotifyResult = (0, util_1.runBeforeNotifyHandlers)(notice, this.__beforeNotifyHandlers);
        if (!preConditionError && !beforeNotifyResult) {
            this.logger.debug('skipping error report: beforeNotify handlers returned false', notice);
            preConditionError = new Error('beforeNotify handlers returned false');
        }
        if (preConditionError) {
            (0, util_1.runAfterNotifyHandlers)(notice, this.__afterNotifyHandlers, preConditionError);
            return false;
        }
        this.addBreadcrumb('Honeybadger Notice', {
            category: 'notice',
            metadata: {
                message: notice.message,
                name: notice.name,
                stack: notice.stack
            }
        });
        var breadcrumbs = this.__store.getContents('breadcrumbs');
        notice.__breadcrumbs = this.config.breadcrumbsEnabled ? breadcrumbs : [];
        (0, util_1.getSourceForBacktrace)(sourceCodeData, this.__getSourceFileHandler)
            .then(function (sourcePerTrace) {
            sourcePerTrace.forEach(function (source, index) {
                notice.backtrace[index].source = source;
            });
            var payload = _this.__buildPayload(notice);
            _this.__transport
                .send({
                headers: {
                    'X-API-Key': _this.config.apiKey,
                    'Content-Type': 'application/json',
                    'Accept': 'text/json, application/json'
                },
                method: 'POST',
                endpoint: (0, util_1.endpoint)(_this.config.endpoint, '/v1/notices/js'),
                maxObjectDepth: _this.config.maxObjectDepth,
                logger: _this.logger,
                async: (0, util_1.isBrowserConfig)(_this.config) ? _this.config.async : undefined,
            }, payload)
                .then(function (res) {
                if (res.statusCode !== 201) {
                    (0, util_1.runAfterNotifyHandlers)(notice, _this.__afterNotifyHandlers, new Error("Bad HTTP response: ".concat(res.statusCode)));
                    _this.logger.warn("Error report failed: unknown response from server. code=".concat(res.statusCode));
                    return;
                }
                var uuid = JSON.parse(res.body).id;
                (0, util_1.runAfterNotifyHandlers)((0, util_1.merge)(notice, {
                    id: uuid
                }), _this.__afterNotifyHandlers);
                _this.logger.info("Error report sent \u26A1 https://app.honeybadger.io/notice/".concat(uuid));
            })
                .catch(function (err) {
                _this.logger.error('Error report failed: an unknown error occurred.', "message=".concat(err.message));
                (0, util_1.runAfterNotifyHandlers)(notice, _this.__afterNotifyHandlers, err);
            });
        });
        return true;
    };
    /**
     * An async version of {@link notify} that resolves only after the notice has been reported to Honeybadger.
     * Implemented using the {@link afterNotify} hook.
     * Rejects if for any reason the report failed to be reported.
     * Useful in serverless environments (AWS Lambda).
     */
    Client.prototype.notifyAsync = function (noticeable, name, extra) {
        var _this = this;
        if (name === void 0) { name = undefined; }
        if (extra === void 0) { extra = undefined; }
        return new Promise(function (resolve, reject) {
            var applyAfterNotify = function (partialNotice) {
                var originalAfterNotify = partialNotice.afterNotify;
                partialNotice.afterNotify = function (err) {
                    originalAfterNotify === null || originalAfterNotify === void 0 ? void 0 : originalAfterNotify.call(_this, err);
                    if (err) {
                        return reject(err);
                    }
                    resolve();
                };
            };
            // We have to respect any afterNotify hooks that come from the arguments
            var objectToOverride;
            if (noticeable.afterNotify) {
                objectToOverride = noticeable;
            }
            else if (name && name.afterNotify) {
                objectToOverride = name;
            }
            else if (extra && extra.afterNotify) {
                objectToOverride = extra;
            }
            else if (name && typeof name === 'object') {
                objectToOverride = name;
            }
            else if (extra) {
                objectToOverride = extra;
            }
            else {
                objectToOverride = name = {};
            }
            applyAfterNotify(objectToOverride);
            _this.notify(noticeable, name, extra);
        });
    };
    Client.prototype.makeNotice = function (noticeable, name, extra) {
        if (name === void 0) { name = undefined; }
        if (extra === void 0) { extra = undefined; }
        var notice = (0, util_1.makeNotice)(noticeable);
        if (name && !(typeof name === 'object')) {
            var n = String(name);
            name = { name: n };
        }
        if (name) {
            notice = (0, util_1.mergeNotice)(notice, name);
        }
        if (typeof extra === 'object' && extra !== null) {
            notice = (0, util_1.mergeNotice)(notice, extra);
        }
        if ((0, util_1.objectIsEmpty)(notice)) {
            return null;
        }
        var context = this.__store.getContents('context');
        var noticeTags = this.__constructTags(notice.tags);
        var contextTags = this.__constructTags(context['tags']);
        var configTags = this.__constructTags(this.config.tags);
        // Turning into a Set will remove duplicates
        var tags = noticeTags.concat(contextTags).concat(configTags);
        var uniqueTags = tags.filter(function (item, index) { return tags.indexOf(item) === index; });
        notice = (0, util_1.merge)(notice, {
            name: notice.name || 'Error',
            context: (0, util_1.merge)(context, notice.context),
            projectRoot: notice.projectRoot || this.config.projectRoot,
            environment: notice.environment || this.config.environment,
            component: notice.component || this.config.component,
            action: notice.action || this.config.action,
            revision: notice.revision || this.config.revision,
            tags: uniqueTags,
        });
        var backtraceShift = 0;
        if (typeof notice.stack !== 'string' || !notice.stack.trim()) {
            notice.stack = (0, util_1.generateStackTrace)();
            backtraceShift = 2;
        }
        notice.backtrace = (0, util_1.makeBacktrace)(notice.stack, backtraceShift);
        return notice;
    };
    Client.prototype.addBreadcrumb = function (message, opts) {
        if (!this.config.breadcrumbsEnabled) {
            return;
        }
        opts = opts || {};
        var metadata = (0, util_1.shallowClone)(opts.metadata);
        var category = opts.category || 'custom';
        var timestamp = new Date().toISOString();
        this.__store.addBreadcrumb({
            category: category,
            message: message,
            metadata: metadata,
            timestamp: timestamp
        });
        return this;
    };
    Client.prototype.__getBreadcrumbs = function () {
        return this.__store.getContents('breadcrumbs').slice();
    };
    Client.prototype.__getContext = function () {
        return this.__store.getContents('context');
    };
    Client.prototype.__developmentMode = function () {
        if (this.config.reportData === true) {
            return false;
        }
        return (this.config.environment && this.config.developmentEnvironments.includes(this.config.environment));
    };
    Client.prototype.__buildPayload = function (notice) {
        var headers = (0, util_1.filter)(notice.headers, this.config.filters) || {};
        var cgiData = (0, util_1.filter)(__assign(__assign({}, notice.cgiData), (0, util_1.formatCGIData)(headers, 'HTTP_')), this.config.filters);
        return {
            notifier: notifier,
            breadcrumbs: {
                enabled: !!this.config.breadcrumbsEnabled,
                trail: notice.__breadcrumbs || []
            },
            error: {
                class: notice.name,
                message: notice.message,
                backtrace: notice.backtrace,
                fingerprint: notice.fingerprint,
                tags: notice.tags,
                causes: (0, util_1.getCauses)(notice),
            },
            request: {
                url: (0, util_1.filterUrl)(notice.url, this.config.filters),
                component: notice.component,
                action: notice.action,
                context: notice.context,
                cgi_data: cgiData,
                params: (0, util_1.filter)(notice.params, this.config.filters) || {},
                session: (0, util_1.filter)(notice.session, this.config.filters) || {}
            },
            server: {
                project_root: notice.projectRoot,
                environment_name: notice.environment,
                revision: notice.revision,
                hostname: this.config.hostname,
                time: new Date().toUTCString()
            },
            details: notice.details || {}
        };
    };
    Client.prototype.__constructTags = function (tags) {
        if (!tags) {
            return [];
        }
        return tags.toString().split(TAG_SEPARATOR).filter(function (tag) { return NOT_BLANK.test(tag); });
    };
    return Client;
}());
exports.Client = Client;
